<template>
  <div>
    <demographics
      :lab-result="labResult"
      :loading="loading"
    />
    <v-row
      class="pt-3 pb-1 text-center"
    >
      <v-col
        cols="12"
        lg="6"
      >
        <arkscore-bar
          :key="'arkscore-bar-' + labResult.score_infection_complexity"
          :score="labResult.score_infection_complexity"
          :name="$t('arkscore.infection_complexity')"
          :height="10"
          icon-guides
          class="mt-n2"
        />
      </v-col>
      <v-col
        cols="12"
        lg="6"
      >
        <arkscore-bar
          :key="'arkscore-bar-' + labResult.score_antimicrobial_resistance"
          :score="labResult.score_antimicrobial_resistance"
          :name="$t('arkscore.antimicrobial_resistance')"
          :height="10"
          icon-guides
          class="mt-n2"
        />
      </v-col>
    </v-row>
    <v-divider />
    <v-row>
      <v-col lg="6">
        <v-list subheader>
          <v-subheader>
            <h3>
              <v-icon
                class="mr-2"
              >
                fal fa-disease
              </v-icon>
              {{ $tc('organism', 2) | capitalize }}
            </h3>
          </v-subheader>
          <v-skeleton-loader
            :loading="loading"
            type="organism-list-item@8"
            :types="skeletons"
            fade-transition
          >
            <span v-if="detected_organisms || undetected_organisms">
              <template v-for="(organism, index) in detected_organisms">
                <v-divider
                  :key="'organism-divider-'+index"
                  xv-if="index > 0"
                />
                <OrganismItem
                  :key="`organism-item-${organism.id}`"
                  :organism="organism"
                  :index="index"
                  @updated="$emit('organism-updated')"
                />
              </template>
              <v-divider
                v-if="undetected_organisms.length"
              />
              <v-list-group
                v-if="undetected_organisms.length"
                :value="false"
              >
                <template #activator>
                  <v-list-item-content>
                    <v-list-item-subtitle>{{ $t('undetected_organisms') }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
                <template v-for="(organism, index) in undetected_organisms">
                  <v-divider
                    :key="'organism-divider-'+index"
                  />
                  <OrganismItem
                    :key="organism.name"
                    :organism="organism"
                    :index="index"
                    class="grey--text"
                    @updated="$emit('organism-updated')"
                  />
                </template>
              </v-list-group>
            </span>
          </v-skeleton-loader>
        </v-list>
      </v-col>
      <v-col lg="6">
        <v-list
          v-if="Array.isArray(labResult.allergies) && labResult.allergies.length"
          subheader
        >
          <v-subheader>
            <v-icon
              size="22"
              class="mr-3"
            >
              fal fa-allergies
            </v-icon>
            <h3>
              {{ $t('Allergies Reported') }}
            </h3>
          </v-subheader>
          <v-divider />
          <template
            v-for="(allergy, index) in labResult.allergies"
          >
            <v-divider
              v-if="index > 0"
              :key="'allergy-divider-'+index"
            />
            <allergen-item
              :key="`allergy-${allergy.id}`"
              :allergen="allergy"
              @updated="$emit('allergen-updated')"
            />
          </template>
        </v-list>
        <v-list subheader>
          <v-subheader>
            <v-icon
              size="22"
              class="mr-3"
            >
              fal fa-shield-virus
            </v-icon>
            <h3>
              {{ $t('Resistance Genes') }}
            </h3>
          </v-subheader>
          <v-divider />
          <v-skeleton-loader
            :loading="loading"
            type="resistance-list-item@3"
            :types="skeletons"
            fade-transition
          >
            <v-list-item
              v-if="labResult.resistance_genes && !labResult.resistance_genes.length"
              selectable
            >
              <v-list-item-icon>
                <v-icon small>
                  fal fa-ban
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title>Not tested</v-list-item-title>
            </v-list-item>
            <span v-else>
              <template v-for="(gene, index) in labResult.resistance_genes?.filter(x => x.result.detected)">
                <v-divider
                  v-if="index > 0"
                  :key="'resistance-divider-'+index"
                />
                <resistance-item
                  :key="`gene-${gene.gene}`"
                  :gene="gene"
                  :organisms="detected_organisms.filter(x => x.significant).length ? detected_organisms.filter(x => x.significant) : detected_organisms.filter(x => x.controversial)"
                  @updated="$emit('resistance-updated')"
                />
              </template>
              <v-divider v-if="labResult.resistance_genes && labResult.resistance_genes?.filter(x => x.result.detected).length" />
              <v-list-group
                v-if="labResult.resistance_genes && labResult.resistance_genes?.filter(x => !x.result.detected).length"
                :value="false"
              >
                <template #activator>
                  <v-list-item-content>
                    <v-list-item-subtitle>{{ $t('undetected_genes') }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
                <template v-for="(gene, index) in labResult.resistance_genes?.filter(x => !x.result.detected)">
                  <v-divider :key="'resistance-divider-'+index" />
                  <resistance-item
                    :key="`gene-${gene.gene}`"
                    :gene="gene"
                    @updated="$emit('resistance-updated')"
                  />
                </template>
              </v-list-group>
            </span>
          </v-skeleton-loader>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>
<script>
  export default {
    components: {
      Demographics: () => import('@/components/lab_result/Demographics.vue'),
      ArkscoreBar: () => import('@/components/arkscore/ArkscoreBar.vue'),
      OrganismItem: () => import('@/components/lab_result/OrganismItem.vue'),
      ResistanceItem: () => import('@/components/lab_result/ResistanceItem.vue'),
      AllergenItem: () => import('@/components/lab_result/AllergenItem.vue'),
    },
    props: {
      labResult: {
        type: Object,
        default: () => ({}),
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      organism_dialog: {},
      skeletons: {
        'organism-list-item': 'divider, list-item-avatar',
        'resistance-list-item': 'divider, list-item-avatar',
      },
    }),
    computed: {
      detected_organisms () {
        return this.labResult.organisms ? this.labResult.organisms.filter(x => x.result.detected) : []
      },
      undetected_organisms () {
        return this.labResult.organisms ? this.labResult.organisms.filter(x => !x.result.detected) : []
      },
    },
  }
</script>
